@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    html, body {
        @apply bg-white text-[#433c3d] font-sans m-0 min-h-screen;
    }

    .form-label {
        @apply block text-sm leading-5 text-slate-500;
    }

    [type=text], [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], textarea, select, select[multiple] {
        @apply w-full border-slate-200 border;
    }

    [type=text], [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], [multiple], textarea, select {
        @apply appearance-none bg-white border py-2 px-3 text-base;
    }

    .btn {
        @apply border border-transparent px-4 py-3 text-sm font-bold rounded-md hover:md:scale-[1.01] uppercase;
    }

    .btn-orange {
        @apply bg-orange text-white shadow-none;
    }

    .btn-paypal {
        @apply bg-[#2e76bf] text-white shadow-none;
    }

    .btn-mobile {
        @apply  leading-[1.5rem] fixed uppercase bottom-0 bg-orange text-sm md:text-base rounded-none w-full -mb-1 px-4 py-4 font-bold text-white text-center z-10 max-w-[430px] left-1/2 transform -translate-x-1/2;
    }

    .btn-mobile-paypal {
        @apply fixed uppercase bottom-0 bg-[#2e76bf] text-sm md:text-base rounded-none w-full -mb-1 px-4 py-4 font-bold text-white text-center z-10 max-w-[430px] left-1/2 transform -translate-x-1/2;
    }

    .disabled, [disabled], :disabled {
        @apply cursor-not-allowed;
        background-color: #eee;
    }

    .peer:checked ~ label .peer-checked\:block {
        @apply block;
    }

    .form-control {
        @apply outline-orange-light py-0 font-semibold w-full mt-1 rounded-md;
        padding-left: 8px;
        padding-right: 8px;

        &:not(textarea) {
            height: 42px;
        }
    }

    input:focus-visible, textarea:focus-visible {
        @apply outline-orange outline-offset-[-2px] outline-[2px];
        outline-style: solid;
    }

    input[readonly]:focus-visible {
        @apply outline-transparent;
    }

    // Toggle Checkbox HTML

    input:checked ~ .dot {
        @apply translate-x-full;
    }

    input:checked ~ .pill {
        @apply bg-orange;
    }

    input:not(:checked) ~ .pill {
        @apply bg-gray-200 border border-slate-200;
    }

    .form-control + .select2,
    .select2-container {
        @apply flex self-stretch w-auto mt-1;
        height: 42px;

        .select2-dropdown--above {
            @apply rounded-t-md;
        }

        .select2-dropdown--below {
            @apply rounded-b-md;
        }

        .selection {
            @apply w-full;
        }

        .select2-selection {
            @apply bg-white rounded-md border-slate-200 outline-none appearance-none shadow-none h-full;
            padding-right: 30px;

            span.select2-selection__arrow,
            .select2-selection__clear span {
                @apply flex items-start h-full font-semibold flex-col justify-center;
            }

            span.select2-selection__rendered {
                @apply line-clamp-1 pr-[8px] truncate leading-[40px] block items-start h-full font-semibold flex-col justify-center;
                width: calc(100% - 30px);
            }

            .select2-selection__clear {
                @apply mr-0 text-xl;
                height: 42px;
                width: 30px;
                margin-top: -3px;
                line-height: 42px;
                color: #888;

                span {
                    @apply inline-flex;
                }
            }

            .select2-selection__arrow {
                @apply right-0 top-0;
                width: 30px;

                // b {
                    // @apply border-t-orange;
                // }
            }
        }

        &.select2-container--open {
            .select2-search__field {
                @apply p-2;
            }

            .select2-selection {
                @apply border-orange;
            }

            // .select2-selection .select2-selection__arrow b {
                // @apply border-b-orange;
            // }
        }
    }

    .ribbon {
        @apply absolute py-[5px] px-0 top-0 right-0 z-10 text-xs uppercase p-0 my-0 mx-auto bg-orange text-white font-bold;
        transform: translateX(30%) translateY(0%) rotate(45deg) scale(1);
        transform-origin: top left;

        &::before {
            @apply absolute top-0 my-0 h-full bg-orange;
            margin-left: calc(-100% - 10px);
            width: calc(100% + 10px);
            content: '';
        }

        &::after {
            @apply bg-orange top-0 absolute my-0 h-full;
            margin-right: calc(-100% - 10px);
            width: calc(100% + 10px);
            content: '';
        }

        &.ribbon-expired {
            @apply bg-gray-200 text-gray-400;
        }

        &.ribbon-expired::before {
            @apply absolute top-0 my-0 h-full bg-gray-200;
            margin-left: calc(-100% - 10px);
            width: calc(100% + 10px);
            content: '';
        }

        &.ribbon-expired::after {
            @apply bg-gray-200 top-0 absolute my-0 h-full;
            margin-right: calc(-100% - 10px);
            width: calc(100% + 10px);
            content: '';
        }
    }

    .whatsapp-fixed {
        @apply bg-orange;
    }
}

.form-control + .select2 {
    width: 100% !important;

    // @media (max-width:768px) {
    //     width: fit-content !important;
    // }

    .select2-selection__arrow b {
        border-width: 8px 6px 0 6px;
    }

    &.select2-container--open .select2-selection__arrow b {
        border-width: 0 6px 8px 6px;
    }
}

select[readonly].form-control + .select2-container--default .select2-selection--single {
    background-color: #eee;
}

span.select2-container--default {
    .select2-dropdown {
        border-color: #fc7b2e;
        font-weight: 600;

        li {
            border-color: #fc7b2e;
        }

        &.select2-dropdown--above li {
            border-bottom-width: 1px;
        }

        &.select2-dropdown--below li {
            border-top-width: 1px;
        }
    }

    .select2-results > .select2-results__options {
        max-height: 400px;
    }

    .select2-results__option--selected {
        background-color: #fff8f5;
    }

    .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: #fc7b2e;

        div {
            color: #ffffff !important;
        }
    }
}

div.swal2-container.swal2-bottom {
    width: 100%;
    padding: 0;
    max-width: 430px;
    left: 50%;
    transform: translateX(-50%);

    div.swal2-popup {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

div.swal2-popup {
    padding: 0;
    overflow: hidden;
}

h2.swal2-title {
    line-height: 1;
}

div.swal2-header {
    padding: 1.25em 3.05em 0;
}

div.swal2-content {
    padding: 0 1.5em;

    @media (max-width: 768px) {
        padding: 0 1.5rem;
    }
}

.swal2-actions button,
.swal2-actions button:focus {
    font-size: 100% !important;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    padding: 1em 2em;
    box-shadow: none;
    outline: none;
    margin: 0;
    display: block;
    width: 100%;
    border-radius: 0 !important;

    &.swal2-confirm {
        background-color: #fc7b2e;
    }
}

div.swal2-validation-message {
    background-color: #fff8f5;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    color: #6b7280;
}

.pulse-inactive {
    border-radius: 100%;
    background-color: rgba(239,68,68, 1);
    box-shadow: 0 0 0 0 rgba(239,68,68, 1);
    transform: scale(1.1);
    animation: pulse_inactive 2s infinite;
}

@keyframes pulse_inactive {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(239,68,68, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(239,68,68, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(239,68,68, 0);
    }
}

.pulse-active {
    border-radius: 100%;
    background-color: rgba(34, 197, 94, 1);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
    transform: scale(1.1);
    animation: pulse_active 2s infinite;
}

@keyframes pulse_active {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 5px rgba(34, 197, 94, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.tawk-min-chat-icon {
    width: 25px !important;
    height: 25px !important;
}

.tawk-min-container, .tawk-min-container .tawk-button {
    width: 40px !important;
    height: 40px !important;
}

[class^="tab-item-animate"] {

}

.tab-item-animate-2,
.tab-item-animate-3 {
    position: absolute;
    height: 46px;
    transform-origin: 0 0;
    transition: transform 0.25s;
    left: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}
.tab-item-animate-2 {
    width: 50%;
}
.tab-item-animate-3 {
    width: 33.333%;
}

.tabs-2 .tabs-item:first-child.active ~ .tab-item-animate-2,
.tabs-3 .tabs-item:first-child.active ~ .tab-item-animate-3 {
    left: 0;
}

.tabs-2 .tabs-item:nth-child(2).active ~ .tab-item-animate-2 {
    left: 50%;
}

.tabs-3 .tabs-item:nth-child(2).active ~ .tab-item-animate-3 {
    left: 33.333%;
}
.tabs-3 .tabs-item:nth-child(3).active ~ .tab-item-animate-3 {
    left: calc(33.333% * 2);
}

input[type="color" i]::-webkit-color-swatch-wrapper {
    border: 0;
    padding: 0;
}

.form-control[type=color] {
    padding: 0;
    overflow: hidden;
}

.whatsapp-fixed svg {
    color: #ffffff;
    fill: #ffffff;
}

@media (max-width: 768px) {
    .whatsapp-fixed {
        bottom: 70px !important;
    }
}

.whatsapp-fixed {
    outline: none !important;
    visibility: visible !important;
    resize: none !important;
    overflow: visible !important;
    opacity: 1 !important;
    top: auto !important;
    bottom: 20px;
    left: auto !important;
    right: 20px !important;
    position: fixed !important;
    border: 0px !important;
    padding: 15px 17px !important;
    transition-property: none !important;
    z-index: 1000001 !important;
    cursor: pointer !important;
    float: none !important;
    pointer-events: auto !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 10px 0px !important;
    height: 60px !important;
    min-height: 60px !important;
    max-height: 60px !important;
    width: 60px !important;
    min-width: 60px !important;
    max-width: 60px !important;
    border-radius: 50% !important;
    transform: rotate(0deg) translateZ(0px) !important;
    transform-origin: 0px center !important;
    margin: 0px !important;
    display: block !important;
}
